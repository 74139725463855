import React from 'react';
import {tabbable} from 'utils/accessibility'

const tags = {
  volunteer: {
    label: 'Volunteer',
  },
  register: {
    label: 'Register to Vote',
  },
  contact: {
    label: 'Contact Elected Officials',
  },
  organize: {
    label: 'Organize a Voter Registration Drive',
  },
  rights: {
    label: 'Voting Rights',
  },
  elections: {
    label: 'Elections',
  },
  other: {
    label: 'Other Issues',
  },
  education: {
    label: 'Voter Education',
  },
  information: {
    label: 'Information',
  },
  media: {
    label: 'Media',
  },
  calculators: {
    label: 'Calculators',
  },
  research: {
    label: 'research'
  },
  issues: {
    label: 'Voting Issues',
  },
  criminalJustice: {
    label: 'Criminal Justice'
  },
  voterTurnout: {
    label: 'Voter turnout data'
  },
  demographicData: {
    label: 'Demographic Data'
  },
}

const actionItems = [
  {
    title: 'HeadCount',
    text: 'HeadCount is a non-partisan organization that works with musicians to promote participation in democracy.',
    tags: [tags.volunteer, tags.register],
    link: 'https://www.headcount.org'
  },
  {
    title: 'Rock the Vote',
    text: 'Rock the Vote is a nonpartisan nonprofit dedicated to building the political power of young people.',
    tags: [tags.volunteer, tags.register, tags.contact],
    link: 'https://www.rockthevote.org'
  },
  {
    title: 'The American Association  of University Women (AAUW)',
    text: 'The American Association of University Women (AAUW) is the nation’s leading voice promoting equity and education for women and girls.',
    tags: [tags.organize],
    link: 'https://www.aauw.org/resource/organize-a-voter-registration-drive/'
  },
  {
    title: 'The League of Women Voters',
    text: 'The League of Women Voters of the United States encourages informed and active participation in government, works to increase understanding of major public policy issues, and influences public policy through education and advocacy.',
    tags: [tags.rights, tags.elections, tags.other],
    link: 'https://www.lwv.org'
  },
  {
    title: 'The National Association for the Advancement of Colored People (NAACP)',
    text: 'The mission of the National Association for the Advancement of Colored People (NAACP) is to secure the political, educational, social, and economic equality of rights in order to eliminate race-based discrimination and ensure the health and well-being of all persons.',
    tags: [tags.education],
    link: 'https://www.naacp.org/campaigns/voter-registration/'
  },
  {
    title: 'Our Homes, Our Votes',
    text: 'Our Homes, Our Votes: 2020 is a non-partisan campaign to register, educate, and mobilize more low income renters and affordable housing advocates to be involved in voting.',
    tags: [tags.education, tags.register],
    link: 'https://www.ourhomes-ourvotes.org'
  },
  {
    title: 'Election Tools',
    text: 'A civic engagement toolkit for election officials.',
    tags: [tags.information, tags.media, tags.calculators],
    link: 'https://electiontools.org'
  },
  {
    title: 'Electoral Integrity Project',
    text: 'EIP has sought to produce innovative and policy-relevant scientific research that achieves international standing in the social sciences and leads to a significant advancement of capabilities and knowledge about elections, democracy, and autocracy.',
    tags: [tags.research],
    link: 'https://www.electoralintegrityproject.com/'
  },
  {
    title: 'The Brennan Center',
    text: 'Through practical policy proposals, litigation, advocacy, and communications, the Brennan Center works to ensure that voting is free, fair, and accessible for all Americans.',
    tags: [tags.issues],
    link: 'https://www.brennancenter.org/issues/voting-rights-elections'
  },
  {
    title: 'FairVote',
    text: 'FairVote is a nonpartisan champion of electoral reforms that give voters greater choice, a stronger voice, and a representative democracy that works for all Americans.',
    tags: [tags.issues],
    link: 'http://www.fairvote.org'
  },
  {
    title: 'The Sentencing Project',
    text: 'Founded in 1986, The Sentencing Project works for a fair and effective U.S. criminal justice system by promoting reforms in sentencing policy, addressing unjust racial disparities and practices, and advocating for alternatives to incarceration.',
    tags: [tags.issues,tags.criminalJustice],
    link: 'https://www.sentencingproject.org/'
  },
  {
    title: 'The United States Elections Project',
    text: 'Information source for the United States electoral system',
    tags: [tags.voterTurnout],
    link: 'http://www.electproject.org/'
  },
  {
    title: 'The Census Bureau',
    text: `The Census Bureau's mission is to serve as the nation’s leading provider of quality data about its people and economy.`,
    tags: [tags.demographicData],
    link: 'https://www.census.gov/topics/public-sector/voting.html'
  }
]

const arrow = () => (
  <svg width="36" height="36" xmlns="http://www.w3.org/2000/svg"><g strokeWidth="2" fill="none" fillRule="evenodd"><path d="M27 23V9H13M9 27L27 9"/></g></svg>
)

const DemocracyModal = () => (
  <div className="conclusion-scene-6__modal__content">
    <p className="subhead">The following websites will allow you to explore today’s voting rights issues in more depth:</p>
    <div className="action-items">
      {actionItems.map(({title,text,tags,link}, index) => {
        return (<div {...tabbable} key={index} className="action-items__item">
          <a href={link} target="_blank" className="head-count"><span>{title}</span> {arrow()} </a>
          <p className="alt">{text}</p>
          <ul className="action-items__item__tags">
            {tags.map(({label}, index) => <li key={index}>{label}</li>)}
          </ul>
        </div>)
      })}
    </div>
  </div>
);

export default DemocracyModal;
