import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import TweenMax, { Expo } from 'gsap/TweenMax';

import './conclusion-scene-3.scss';
import {
  placeholder,
  showElemWithAria,
  hideElementWithAria,
  hideFromBelow,
  revealFromBelow
} from 'utils/animation';
import { getPercentageBetweenBounds } from 'utils';
import { tabbable } from 'utils/accessibility';

import scene from 'components/scene';

import SplitView from 'templates/SplitView';

import FullScreenContainer from 'components/FullScreenContainer';
import ImageSwap from 'components/ImageSwap';

import { Image } from 'components/Image';
import timeline from 'components/timelineComponent';

class ConclusionScene3 extends React.Component {
  render() {
    const { position } = this.props;
    const { johnLewis1965, johnLewisFilm, johnLewisSpeaking } = this.props.data;
    const swapProgress = getPercentageBetweenBounds(position, 2 / 7, 3 / 7);
    return (
      <FullScreenContainer className="conclusion-scene-3">
        <div ref="section1" className="conclusion-scene-3__section">
          <SplitView
            fullWidthPanel={
              <ImageSwap
                image={johnLewis1965}
                image2={johnLewisFilm}
                position={swapProgress}
                showAria
                alt="John Lewis At Montgomery State Capitol
                Student Non-violent Coordinating Committee (SNCC) President John Lewis speaks in front of the Montgomery State Capitol building at the conclusion of the Selma to Montgomery March, Montgomery, Alabama, March 25, 1965. (Photo by Steve Schapiro/Corbis via Getty Images)"
                showAria2
                alt2="Congressman John Lewis in Atlanta
              Congressman John Lewis, the former president of SNCC, speaks at home in Atlanta. (Photo by © Flip Schulke/CORBIS/Corbis via Getty Images)"
              />
            }
            leftPanel={
              <div {...tabbable} className="conclusion-scene-3__text">
                <p ref="text1">
                  In 2020, one of those students — John Lewis —{' '}
                  <span
                    className="conclusion-scene-3__text__fragment"
                    ref="text1Fragment">
                    is a senior member of Congress.
                  </span>
                </p>
                <p ref="text1Subhead" className="subhead">
                  He is deeply troubled by the <br />
                  <span className="highlight">
                    erosion of the Voting Rights Act
                  </span>
                  <br />
                  by court rulings since its last renewal in 2006.
                </p>
              </div>
            }
          />
        </div>
        <div ref="section2" className="conclusion-scene-3__section section-2">
          <SplitView
            fullWidthPanel={
              <div className="conclusion-scene-3__john-lewis">
                <Image image={johnLewisSpeaking} sizes="100vw" showAria alt="John Lewis, a Democratic representative from Georgia, on day UNITED STATES - AUGUST 28: John Lewis, a Democratic representative from Georgia, on day four of the Democratic National Convention (DNC) at Invesco Field at Mile High in Denver, Colorado, U.S., on Thursday, Aug. 28, 2008. Senator Barack Obama of Illinois will accept his party's nomination for Democratic presidential candidate during his speech at the stadium tonight. (Photo by Daniel Acker/Bloomberg via Getty Images)" />
              </div>
            }
            leftPanel={
              <div {...tabbable} className="conclusion-scene-3__text">
                <p ref="text2a" className="alt">
                  Speaking on the 53rd anniversary of the Voting Rights Act, he
                  declared that
                </p>
                <p ref="text2b" className="subhead">
                  Americans “…must rededicate ourselves to a simple proposition:
                </p>
              </div>
            }
          />
        </div>
      </FullScreenContainer>
    );
  }
}

const animation = ({ context, timeline, easeOut }) => {
  const {
    text1,
    text1Fragment,
    text1Subhead,
    section1,
    section2,
    text2a,
    text2b
  } = context.refs;

  TweenMax.set([text1, text1Fragment, text1Subhead, text2a, text2b], {
    ...hideFromBelow
  });
  TweenMax.set(section1, { ...showElemWithAria });
  TweenMax.set(section2, { ...hideElementWithAria });

  timeline.to(text1, 1.5, { ...revealFromBelow, ease: Expo.easeOut });
  timeline.to(text1Fragment, 1.5, { ...revealFromBelow, ease: Expo.easeOut });
  // console.log(timeline.duration())
  placeholder(timeline, 1);
  // console.log(timeline.duration())
  timeline.to(text1Subhead, 1.5, { ...revealFromBelow, ease: Expo.easeOut });

  timeline.markers.createTimelineMarker();

  timeline.to(section1, 1, { ...hideElementWithAria });
  timeline.to(section2, 1, { ...showElemWithAria }, '-=0.25');
  timeline.to(text2a, 1.5, { ...revealFromBelow, ease: Expo.easeOut });
  timeline.to(
    text2b,
    1.5,
    { ...revealFromBelow, ease: Expo.easeOut },
    '-=1.25'
  );
  // console.log(timeline.duration())

  timeline.markers.createTimelineMarker();
};

const query = graphql`
  query {
    johnLewis1965: file(
      relativePath: {
        eq: "conclusion/scene-3/images/chc-image-john-lewis-1965@2x.png"
      }
    ) {
      ...mediumImage
    }
    johnLewisFilm: file(
      relativePath: {
        eq: "conclusion/scene-3/images/chc-image-john-lewis-film@2x.png"
      }
    ) {
      ...mediumImage
    }
    johnLewisSpeaking: file(
      relativePath: {
        eq: "conclusion/scene-3/images/chc-image-john-lewis-2016@2x.png"
      }
    ) {
      ...mediumImage
    }
  }
`;

const Component = scene(timeline(ConclusionScene3, { animation }));

export default props => {
  const data = useStaticQuery(query);
  return <Component data={data} {...props} />;
};
