import React from 'react';
import PropTypes from 'prop-types';
import FullScreenContainer from 'components/FullScreenContainer';
import FullScreenVideoPlayer from 'components/FullScreenVideoPlayer';
import CenterView from 'templates/CenterView';

class WrapVideo extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {src, content, loop} = this.props;

    return (
      <div ref={(ref) => this.el = ref} >
        <FullScreenContainer>
          <CenterView content={content}
            background={(
              <FullScreenVideoPlayer
                src={src}
                autoplay
                muted
                playsinline
                loop={loop}
              />
            )}/>
        </FullScreenContainer>
      </div>
    );
  }
};

WrapVideo.propTypes = {
  loop: PropTypes.bool,
  src: PropTypes.string,
  content: PropTypes.object,
};

export default WrapVideo;
