import React from 'react';
import TweenMax from 'gsap/TweenMax';

import timeline from 'components/timelineComponent';
import WrapVideo from 'templates/WrapVideo';
import {
  hideFromBelow, revealFromBelow,
} from 'utils/animation';
import {tabbable} from 'utils/accessibility';

import scene from 'components/scene';

import ConclusionIntro from './ch8_conc-LOOP-01.mp4';
import './conclusion-scene-4.scss';

class ConclusionScene4 extends React.Component {
  render() {
    return (
      <WrapVideo loop={true}
        src={ConclusionIntro}
        content={(
          <div className="conclusion-scene-4__text">
            <h2>
              <span {...tabbable} ref="text1" className="uppercase">“Do everything possible</span>
              <span {...tabbable} ref="text2" className="uppercase">to help people vote,</span>
              <span {...tabbable} ref="text3" className="small">not stop them from voting.”</span>
            </h2>
          </div>
        )} />
    );
  }
}

const animation = ({context, timeline, easeOut}) => {
  const {text1, text2, text3} = context.refs;

  TweenMax.set([text1, text2, text3], {...hideFromBelow});

  timeline.to(text1, 1.5, {...revealFromBelow});
  timeline.to(text2, 1.5, {...revealFromBelow}, '-=0.5');
  timeline.to(text3, 1.5, {...revealFromBelow}, '-=0.5');
};

export default scene(timeline(ConclusionScene4, {animation}));
