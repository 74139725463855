import React, {Component} from 'react';
import TweenMax from 'gsap/TweenMax';

import timeline from 'components/timelineComponent';
import WrapVideo from 'templates/WrapVideo';
import {
  showElemWithAria, hideElementWithAria,
} from 'utils/animation';
import {tabbable} from 'utils/accessibility';

import scene from 'components/scene';

import WeThePeople from './CON-We_The_People.mp4';
import './conclusion-scene-5.scss';

class ConclusionScene5 extends Component {
  render() {
    return (
      <WrapVideo loop={false}
        src={WeThePeople}
        content={(
          <div className="conclusion-scene-5__text">
            <p {...tabbable} ref="text1">We the People of the United States of America,</p>
            <p {...tabbable} ref="text2">must do everything possible.</p>
          </div>
        )} />
    );
  }
}

const animation = ({context, timeline, easeOut}) => {
  const {text1, text2} = context.refs;

  TweenMax.set(text1, {...hideElementWithAria, y: 50});
  TweenMax.set(text2, {...hideElementWithAria, y: 50});

  timeline.to(text1, 1, {...showElemWithAria, y: 0});
  timeline.markers.createTimelineMarker();
  timeline.to(text2, 1, {...showElemWithAria, y: 0});

  timeline.markers.createTimelineMarker();
};

export default scene(timeline(ConclusionScene5, {animation}));
