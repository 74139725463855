export const ConclusionQa = [
  {
    questionNumber: '8.01',
    question: 'Why have some people challenged the Voting Rights Act in recent years?',
    answer: `Answers will vary and may include: felon disenfranchisement laws by state exclude millions from voting, state level voter ID laws disproportionately disenfranchise communities of color and people experiencing poverty, Shelby v. Holder lifted the federal oversight requirement, some believe voter fraud is an issue.`,
  },
  {
    questionNumber: '8.02',
    question: 'How are voting rights threatened today? How can we protect those rights?',
    answer: `Answers will vary and may include; Contemporary voter ID requirements, gerrymandering, voting cyber security, reductions in early voting opportunities, and polling places and voting roll purges disproportionately target people of color and threaten voting rights today. On average, Hispanic voters spend one and a half times as long in line waiting to vote than their white counterparts. African Americans spend nearly twice as long in line. We can protect the right to vote through voter reinstatement programs for persons formerly convicted of a felony.`,
  },
  {
    questionNumber: '8.03',
    question: 'How have voting rights expanded over the course of American history?',
    answer: `Answers will vary and may include: For several decades, voting in most states was limited to white male landowners, called freeholders. Gradually, the franchise, or right to vote, expanded to include non-landowners, then African-American men, then women, and eventually people as young as 18. Expanding the right to vote required action from hundreds of people who demanded they were given their right as well as the federal government taking a larger role in defining who made up the electorate, or people who could vote.`,
  },
  {
    questionNumber: '8.04',
    question: 'What effect did the civil rights movement have on racial discrimination and injustice? In what ways was it successful? In what ways do racial discrimination and injustice still exist?',
    answer: `Answers will vary.`,
  },
];
