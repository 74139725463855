import React from 'react';

import ChapterPage from 'components/chapter';

import ConclusionScene2 from 'components/scenes/conclusion/scene-2/ConclusionScene2';
import ConclusionScene3 from 'components/scenes/conclusion/scene-3/ConclusionScene3';
import ConclusionScene4 from 'components/scenes/conclusion/scene-4/ConclusionScene4';
import ConclusionScene5 from 'components/scenes/conclusion/scene-5/ConclusionScene5';
import ConclusionScene6 from 'components/scenes/conclusion/scene-6/ConclusionScene6';
import VideoIntroduction from '../components/VideoIntroduction';
import conclusionVideo from 'components/scenes/conclusion/scene-1/conclusion-intro.mp4';

export class Conclusion extends React.Component {
  render() {
    const title = 'Selma: Conclusion';

    return (
      <div className="conclusion">
        <ChapterPage
          title={title}
          scenes={[
            <VideoIntroduction
              src={conclusionVideo}
            />,
            <ConclusionScene2 />,
            <ConclusionScene3 />,
            <ConclusionScene4 />,
            <ConclusionScene5 />,
            <ConclusionScene6 theme="dark" />,
          ]}
        />
      </div>
    );
  }
}

export default Conclusion;
