import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import TweenMax, {Expo} from 'gsap/TweenMax';

import './conclusion-scene-2.scss';
import {
  placeholder, hideAllRefs, showElemWithAria, hideElementWithAria, hideFromBelow, revealFromBelow
} from 'utils/animation';
import {tabbable} from 'utils/accessibility'

import scene from 'components/scene';

import CenterView from 'templates/CenterView';

import FullScreenContainer from 'components/FullScreenContainer';
import FullScreenImage from 'components/FullScreenImage';

import {Image} from 'components/Image';
import timeline from 'components/timelineComponent';

class ConclusionScene2 extends React.Component {
  render() {
    const {
      studentsSitProtest,
      fillingOutBallot,
    } = this.props.data;
    return (
      <FullScreenContainer className="conclusion-scene-2">
        <div className="students-sit" >
          <CenterView background={
            <div className="students-sit__images">
              <div className="students-sit__images__image student-image" ref="studentsSit">
                <Image image={studentsSitProtest} alt="Students sit Protest"/>
              </div>
              <div className="students-sit__images__image" ref="voting">
                <FullScreenImage
                  image={fillingOutBallot}
                  alt="Hand dropping ballot"
                  hideImage={true}
                  aspectRatio={8/5}
                >
                  <svg viewBox="0 0 100 62.5" preserveAspectRatio="none">
                    <defs>
                      <radialGradient id="votingMaskGradient">
                        <stop offset="10%" stopColor="white" />
                        <stop ref="stop" offset="95%" stopColor="black" />
                      </radialGradient>

                      <mask id="votingMask">
                        <circle ref="circleMask" cx="48%" cy="15%" r="15%" fill="url('#votingMaskGradient')" />
                      </mask>
                    </defs>
                    <image ref="votingImage"
                      preserveAspectRatio="xMinYMin slice"
                      xlinkHref={fillingOutBallot.childImageSharp.fluid.src}
                      width="100%" height="100%" mask="url(#votingMask)"
                      alt="Hand dropping ballot"
                    />
                  </svg>
                </FullScreenImage>
              </div>
            </div>
          } content={
            <div>
              <div className="students-sit__text">
                <div className="students-sit__text__paragraph text-1">
                  <p {...tabbable} ref="text1" className="subhead">In Selma in 1965, students believed they had the power to change their world.</p>
                </div>
                <div className="students-sit__text__paragraph text-2">
                  <p {...tabbable} ref="text2" className="subhead">Working with community leaders and established reform groups,<br /><span className="highlight">they challenged the old laws</span> that made African Americans in the South<br />second class citizens. </p>
                </div>
              </div>
              <div className="students-sit__text">
                <div className="students-sit__text__paragraph text-3">
                  <p {...tabbable} ref="text3" className="subhead">
                    They demanded—and won—new laws that <br />
                    <span className="strong uppercase">protected the <span className="highlight">right to vote</span></span>.
                  </p>
                </div>
              </div>
            </div>
          } />
        </div>
      </FullScreenContainer>
    );
  }
}

const animation = ({context, timeline, easeOut}) => {

  const {
    studentsSit,
    text1,
    text2,
    text3,
    voting, votingImage, stop, circleMask,
  } = context.refs;

  TweenMax.set(voting, {z: 300, ...hideElementWithAria});
  TweenMax.set(votingImage, {x: '0%', y: '0%'});
  TweenMax.set(stop, {attr: {'stop-color': 'black'}});
  TweenMax.set(circleMask, {attr: {r: '30%', cy: '75%', cx: '70%'}});

  TweenMax.set(studentsSit, {...hideElementWithAria});
  TweenMax.set(voting, {...hideElementWithAria});
  TweenMax.set([text1, text2, text3], {...hideFromBelow});

  timeline.to(studentsSit, 1, {...showElemWithAria});
  timeline.to(text1, 1.5, {...revealFromBelow, ...easeOut});
  timeline.to(text2, 1.5, {...revealFromBelow, ...easeOut});

  timeline.markers.createTimelineMarker()

  timeline.to(studentsSit, 1, {...hideElementWithAria});
  timeline.to(voting, 1, {...showElemWithAria}, '-=1');
  timeline.to(text1, 1, {...hideElementWithAria}, '-=1');
  timeline.to(text2, 1, {...hideElementWithAria}, '-=1');

  timeline.to(voting, 1.5, {z: 0, y: '0%', ease: Expo.easeInOut});
  timeline.to(votingImage, 1.5, {x: '0%', y: '0%', ease: Expo.easeInOut}, '-=1.5');
  timeline.to(circleMask, 1.5, {attr: {r: '1500%', cy: '50%', cx: '50%'}, ease: Expo.easeInOut}, '-=1.5');
  timeline.to(stop, 1, {attr: {'stop-color': 'white'}}, '-=1.25');
  timeline.to(text3, 1.5, {...revealFromBelow, ...easeOut}, '-=1');

  timeline.markers.createTimelineMarker()

};

const query = graphql`
  query {
    studentsSitProtest: file(relativePath: { eq: "conclusion/scene-2/images/chc-image-students-sit-protest-sepia@2x.png" }) {
      ...mediumImage
    }
    fillingOutBallot: file(relativePath: { eq: "conclusion/scene-2/images/chc-image-hand-dropping-ballot@2x.png" }) {
      ...mediumImage
    }
  }
`;

const Component = scene(timeline(ConclusionScene2, {animation}));

export default (props) => {
  const data = useStaticQuery(query);
  return <Component data={data} {...props} />;
}
